<script setup>
// import Pagination from '@/components/Pagination.vue'
import Table from '@/components/Table.vue'
import Content from '@/views/Layouts/Content.vue'
import { ref } from 'vue'

import {
  // BriefcaseIcon,
  // CalendarIcon,
  PlusIcon,
  CogIcon,
  // ChevronDownIcon,
  // CurrencyDollarIcon,
  // LinkIcon,
  // LocationMarkerIcon,
  // PencilIcon,
} from '@heroicons/vue/solid/index.js'

const contacts = ref([
  {
    'id': 1,
    "name": "Имя 1",
    "phones": [
      {
        "phone": "+79017899171",
      },
      {
        "phone": "+79166252529",
      }
    ],
    "comment": "Комментарий 1"
  },
  {
    'id': 2,
    "name": "Имя 2",
    "phones": [
      {
        "phone": "+79017899171",
      },
      {
        "phone": "+79166252529",
      }
    ],
    "comment": "Комментарий 2"
  },
  {
    'id': 3,
    "name": "Имя 3",
    "phones": [
      {
        "phone": "+79017899171",
      },
      {
        "phone": "+79166252529",
      }
    ],
    "comment": ""
  },
])

const header = ref([
  'ID',
  'Имя',
  'Телефон',
  'Комментарий'
])

</script>
<template>
  <div>
    <Content>

      <!-- <template #header-controls> 
        <span class="sm:ml-3">
          <button type="button" class="btn-primary">
            <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Добавить
          </button>
        </span>
        <div class="flex-1">
          <input type="text" placeholder="Фильтр" class="w-full" />
        </div>
        <div>
          <button>
            <CogIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div> 
      </template> -->
      <template #title>Профиль</template>
      <template #content>
        <!-- <Table :items="contacts" :header="header">
          <template #members="membersProps">
            <div :class="{
              'text-red-500': membersProps.value <= 100,
              'text-yellow-500': membersProps.value > 100 && membersProps.value <= 150,
              'text-green-500': membersProps.value > 150 && membersProps.value <= 250
            }">
              <div class="text-xl font-semibold" :title="`${membersProps.value} чел. / ${hallCount} мест`">
                {{ (membersProps.value / hallCount) * 100 }}%
              </div>
            </div>
          </template>
          <template #phones="phonesProps">
            <div class=" ">{{ phonesProps.value[0].phone }}</div>
          </template>
          <template #controls="controlsProps">
            <div class="text-sm">
              <router-link :to="`/contacts/${controlsProps.item.id}`">Редактировать</router-link>
            </div>
          </template>
        </Table> -->
        <!-- <div class="my-3">
          <img src="/tmp/contacts-list.png" alt="" srcset="">
        </div> -->
      </template>
    </Content>
  </div>
</template>